/* eslint-disable no-useless-escape */
'use strict';
var moment = require('moment');

var DatePicker = require('../datepicker/datepicker');
var redirectTicketMobiFacilImport = require('../ticket/redirecTicketMobiFacil');


function saveSearchOnSession(data, chave) {
    var dataString = JSON.stringify(data);
    sessionStorage.setItem(chave, dataString);
}

function getSearchOnSession(chave) {
    var dataString = sessionStorage.getItem(chave);
    if (!dataString) {
        return null;
    }
    return JSON.parse(dataString);
}
function removeSymbols(cityName) {
    var symbols = {
        À: 'A',
        Á: 'A',
        Â: 'A',
        Ã: 'A',
        Ä: 'A',
        È: 'E',
        É: 'E',
        Ê: 'E',
        Ë: 'E',
        Ì: 'I',
        Í: 'I',
        Î: 'I',
        Ï: 'I',
        Ò: 'O',
        Ó: 'O',
        Ô: 'O',
        Õ: 'O',
        Ö: 'O',
        Ù: 'U',
        Ú: 'U',
        Û: 'U',
        Ü: 'U',
        Ç: 'C',
        à: 'a',
        á: 'a',
        â: 'a',
        ã: 'a',
        ä: 'a',
        è: 'e',
        é: 'e',
        ê: 'e',
        ë: 'e',
        ì: 'i',
        í: 'i',
        î: 'i',
        ï: 'i',
        ò: 'o',
        ó: 'o',
        ô: 'o',
        õ: 'o',
        ö: 'o',
        ù: 'u',
        ú: 'u',
        û: 'u',
        ü: 'u',
        ç: 'c'
    };

    var stripedQueryNoSymbol = cityName.replace(/[ÀÁÂÃÄÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÇàáâãäèéêëìíîïòóôõöùúûüç]/g, function (match) {
        return symbols[match];
    });

    return stripedQueryNoSymbol;
}

var lastQueries = {
    origin: '',
    destination: ''
};

var responseMemorized = {
    origin: null,
    destination: null
};

var isSearching = false;

var optionsIn = {
    minDate: moment().format('DD/MM/YYYY'),
    singleDatePicker: true,
    showDropdowns: false,
    autoApply: true,
    parentEl: '[data-datepicker="In"]',
    locale: {
        cancelLabel: 'Limpar',
        applyLabel: 'Comprar',
        format: 'DD/MM/YYYY',
        daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        monthNames: [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ],
        firstDay: 1
    }
};

var optionsOut = DatePicker.utils.copyAndMerge(
    {
        parentEl: '[data-datepicker="Out"]'
    },
    optionsIn
);
$(document).ready(function () {
    $('.cities__origin').focus(function () {
        $('#destination_cities').hide();
    });

    $('.cities__destination').focus(function () {
        $('#origin_cities').hide();
    });
});


$('#ticketForm').ready(function () {
    function validateInputs() {
        var origin = $('.cities__input.cities__origin').val();
        var destination = $('.cities__input.cities__destination').val();
        var button = $('.search-tickets-btn.search-tickets-btn--headerForm');
        var buttonMobile = $('.searchBtn-mobile');
        var departure = $('#inputDeparture').val();
        var isValidOrigin = origin.length > 0;
        var isValidDestination = destination.length > 0;
        var isValidDeparture = departure.length > 0;

        button.prop('disabled', !(isValidOrigin && isValidDestination && isValidDeparture));
        buttonMobile.prop('disabled', !(isValidOrigin && isValidDestination && isValidDeparture));

        if (!isValidOrigin) {
            var originFeedback = $('.cities__origin').siblings('.invalid-feedback');
            originFeedback.text('Insira a cidade de origem').show().delay(3000).fadeOut(500);
        }

        if (!isValidDeparture) {
            var departureFeedback = $('#inputDeparture').siblings('.invalid-feedback');
            departureFeedback.text('Insira uma data').show().delay(3000).fadeOut(500);
        }

        if (!isValidDestination) {
            var destinationFeedback = $('.cities__destination').siblings('.invalid-feedback');
            destinationFeedback.text('Insira a cidade de destino').show().delay(3000).fadeOut(500);
        }
    }
    if ($('.ticket__searchInfos').length >= 1) {
        $('.cities__input.cities__origin, .cities__input.cities__destination, #inputDeparture').on('click input change', function () {
            $(this).siblings('.invalid-feedback').stop(true).css('opacity', '1')
            .hide();
            setTimeout(function() {
                validateInputs();
            }, 1500);
        });

        $('.search-tickets-btn.search-tickets-btn--headerForm, .searchBtn-mobile').on('click mouseenter', function () {
            validateInputs();
        });
    }
});


/**
 * Creates a cookie
 * @param {string} name The name of the cookie
 * @param {object} value The value of the cookie
 * @param {number} days The value of the cookie
 */
function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime((date.getTime() + days) * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    document.cookie =
        encodeURIComponent(name) +
        '=' +
        JSON.stringify(value) +
        expires +
        '; path=/';
}

/**
 * Reads a cookie
 * @param {string} name The name of the cookie
 */
function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) { return JSON.parse(c.substring(nameEQ.length, c.length)); }
    }
    return null;
}

/**
 * Get the search query from the input value
 * @param {string} value The input element value
 */
function getQuery(value) {
    if (typeof value !== 'string') {
        throw new Error('getQuery Method must receive a string!');
    }

    var query = value.trim();

    var queryNoSymbols = removeSymbols(query);

    queryNoSymbols = queryNoSymbols.replace(/[\u0300-\u036f]/g, '');

    return queryNoSymbols.toUpperCase();
}

function highlightedSearch(name, searchTerm) {
    var regex = new RegExp('(' + searchTerm + ')', 'gi');
    return removeSymbols(name).replace(regex, '<strong>$1</strong>');
}

/**
 * Filter the cities according to the query
 * @param {Object} input The input element
 */
function filterOriginsDestinations(travelPoint) {
    var $input = $('.cities__input.cities__' + travelPoint);
    var $list = $input.next('.cities__list');
    var $items = $list.find($('.cities__item'));
    var value = $input.val();
    var query = getQuery(value);

    if (query.length >= 0) {
        //NOTE: Transfere essa lógica para a Ticket.js
        var isRecentTerm = $items.parent('.cities__list-recent').length > 0
        if(travelPoint === 'destination' || isRecentTerm) {
            $items.each(function () {
                if (removeSymbols($(this).text().toUpperCase()).search(query) > -1) {
                    $list.show();
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        }

        $('.cities__list-recent, .cities__list-popular').show();
        if (
            $('.cities__list-recent, .cities__list-popular').children(
                ':visible'
            ).length == 0
        ) {
            $('.cities__list-recent, .cities__list-popular').hide();
        } else {
            $('.cities__list-recent, .cities__list-popular').show();
        }
    }
}
/**
 * Fetch an object with a list of cities given a city name as a query
 * @param {string} travelPoint Can be origins|destionatios
 * @param {string} query city name
 * @param {function} callback that will be executed with the response returned
 */
function requestCitiesFrom(travelPointParam, query, callback) {
    var travelPoint = travelPointParam || 'origins';
    isSearching = true;

    $('.loaderSpin').remove();

    if (travelPoint === 'origins') {
        $('.form-group--isOrigin').append('<div class="loaderSpin"></div>');
    }

    if (travelPoint === 'destinations') {
        $('.form-group--isDestination').append(
            '<div class="loaderSpin"></div>'
        );
    }
    var lastSearch = getSearchOnSession('lastDestinations');

    if (travelPoint === 'origins' || (travelPoint === 'destinations' && !lastSearch)) {
        $.ajax({
            url:
                $('#ticketForm').data(travelPoint) +
                query +
                '&origin=' +
                $('#origin').val(),
            method: 'GET',
            success: function (response) {
                callback(null, response);

                if (travelPoint === 'destinations') {
                    saveSearchOnSession(response, 'lastDestinations');
                }

                isSearching = false;
                $('.loaderSpin').remove();
            },
            error: function (error) {
                callback(error);
                isSearching = false;
                $('.loaderSpin').remove();
            }
        });
    } else {
        if (travelPoint === 'destinations') {
            $.ajax({
                url: $('#ticketForm').data('filter') + query,
                method: 'POST',
                data: {destinations: JSON.stringify(lastSearch.destinations)},
                dataType: 'json',
                success: function (response) {
                    callback(null, response);
                    isSearching = false;
                    $('.loaderSpin').remove();
                },
                error: function (error) {
                    callback(error);
                    isSearching = false;
                    $('.loaderSpin').remove();
                }
            });
        } else {
            callback(null, lastSearch);
            isSearching = false;

            $('.loaderSpin').remove();
        }
    }
}

/**
 * Create a autocomplete list of cities
 * @param {string} travelPoint  Can be origin|destionation
 * @param {object} data The object received from request
 */
function fillList(travelPointParam, stripedQuery, data) {
    try {
        var travelPoint = travelPointParam || 'origin';
        var $list = $('#' + travelPoint + '_cities');

        $list
            .find('.cities__list-search, .cities__list-popular')
            .children()
            .remove();

        const resultOriginDestination = data || [];

        var canRequest =
            (travelPoint === 'origin' &&
                $('.form-control.cities__input.cities__origin').val().length > 0) ||
            (travelPoint === 'destination' &&
                $('.form-control.cities__input.cities__destination').val().length > 0);

        var hasPopular = false;

        $.each(resultOriginDestination, function (index, item) {
            var recentSearch = $list.find('.cities__list-recent').find('li[data-id=' + item.id + ']');

            if ($('body').hasClass('mostPopular') && item.isPopular) {
                if (!hasPopular) {
                    hasPopular = true;
                }

                if (recentSearch && recentSearch.data('id') != Number(item.id)) {
                    $list
                    .find('.cities__list-popular')
                    .append(
                        '<li class="cities__item text--isNormal" data-id="' +
                            item.id +
                            '"orange-id="result_popular_' + index +
                            '">' +
                            highlightedSearch(item.name, stripedQuery) +
                            '</li>'
                    );
                }
            } else if (canRequest) {
                var popularCity = $list.find('.cities__list-popular').find('li[data-id=' + item.id + ']');

                if ((popularCity && popularCity.data('id') != Number(item.id)) && (recentSearch && recentSearch.data('id') != Number(item.id))) {
                    $list
                    .find('.cities__list-search')
                    .append(
                        '<li class="cities__item text--isNormal" data-id="' +
                            item.id +
                            '"orange-id="result_search_' + index +
                            '">' +
                            highlightedSearch(item.name, stripedQuery) +
                            '</li>'
                    );
                }
            }
        });

        $('.cities__item').on('click tap', function () {
            $(this)
                .closest('.cities__list')
                .siblings('.cities__input')
                .val($(this).text());
            $(this)
                .closest('.cities__list')
                .next('.cities__input--isHidden')
                .val($(this).attr('data-id'));
            $(this).closest('.cities__list').hide();

            if (!DatePicker.utils.isMobile() && travelPoint === 'destination') {
                DatePicker.getInstance('ticketPicker').showIn();
            }
        });
        if (data && data.length > 0 && (canRequest || hasPopular)) {
            $list.show();
        } else {
            $list.hide();
        }
    } catch (error) {
        console.error(error)
    }
}

/**
 * getChars return a specified numbers of chars from a string, starting from index 0 (zero)
 * @param {string} query query with the city name
 * @param {number} quantity quantity of characteres to return. default: 3
 */
function getChars(query, quantityParam) {
    var quantity = quantityParam || 1;
    return query.slice(0, quantity);
}
/**
 * Save travel points at sessionStorage
 * @param {string} travelPoint origin|destination
 * @param {string} cityName the city name
 * @param {string} codeCity the code thats represents the city
 */
function saveTravelPoints(travelPoint, cityName, codeCity) {
    if (!('sessionStorage' in window)) return;

    var travelPoints = JSON.parse(
        sessionStorage.getItem('travelPoints') || '{}'
    );

    if (cityName && codeCity) {
        travelPoints[travelPoint + 'Value'] = cityName;
        travelPoints[travelPoint + 'Code'] = codeCity;

        sessionStorage.setItem('travelPoints', JSON.stringify(travelPoints));
    }
}

/**
 * Load a list with all related cities
 * @param {*} travelPoint Can be origin|destination
 * @param {*} value the value with the city name
 */
function filterCities(travelPoint, valueParam) {
    var value = valueParam == '' ? ' ' : valueParam;

    // Pegar as 3 primeiras letras do local
    var query = getQuery(value);
    var stripedQuery = getChars(query, query.length);

    // caso menos de 3 caracteres não fazer requisição e deixar lista vazia
    // if (query.length < 1) return fillList(travelPoint, []);

    // Caso a query é a mesma pesquisada não realizar nova requisição
    if (
        lastQueries[travelPoint] === stripedQuery &&
        responseMemorized[travelPoint] !== null
    ) {
        fillList(travelPoint, stripedQuery, responseMemorized[travelPoint]);

        if (query.length > 0) {
            filterOriginsDestinations(travelPoint);
        }

        return;
    }

    // Armazenar última busca para não realizar a mesma requisição
    lastQueries[travelPoint] = stripedQuery;

    if (!isSearching) {
        // Fazer requisição para pegar a lista de locais
        requestCitiesFrom(
            travelPoint + 's',
            stripedQuery,
            function (err, response) {
                if (!err && response.success) {
                    // cache the response
                    responseMemorized[travelPoint] =
                        response[travelPoint + 's'];
                    // fill the list with the response
                    fillList(
                        travelPoint,
                        stripedQuery,
                        responseMemorized[travelPoint]
                    );
                    filterOriginsDestinations(travelPoint);
                }
            }
        );
    }
}

/**
 * Verify if origin/destination inputs are filled
 */
function hasTravelPoints() {
    return (
        $('.cities__origin').val().length > 0 &&
        $('.cities__destination').val().length > 0
    );
}

function delaySearch(callback, ms) {
    var timer = 0;
    return function () {
        var context = this;
        var args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

/**
 * change origin and destination inputs values
 */
function switchTravelPoints() {
    if (!hasTravelPoints()) return;

    var originCode = $('#origin').val();
    var originValue = $('.cities__input.cities__origin').val();

    var destinationCode = $('#destination').val();
    var destinationValue = $('.cities__input.cities__destination').val();

    $('#origin').val(destinationCode);
    $('.cities__input.cities__origin').val(destinationValue);

    $('#destination').val(originCode);
    $('.cities__input.cities__destination').val(originValue);
}

function adjustDateFormat(date) {
    // Formato recebido: ano/mes/dia - 2023/12/30
    const dateParts = date.split(/[/\-]/);
    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
}

/**
 * Fill the travelpoints inputs at ticketForm
 */
function fillTravelPoints() {
    if (!('sessionStorage' in window)) return;

    var travelPoints = JSON.parse(
        sessionStorage.getItem('travelPoints') || '{}'
    );

    const originDestination = JSON.parse(
        $('#searchPairsInfo').attr('val') || '{}'
    );

    const queryTravel = $('.page').attr('data-querystring');

    const matchDate = /\d{2}\%2F\d{2}\%2F\d{4}/g;

    if ((queryTravel.match(matchDate) || queryTravel.match(/\d{2}-\d{2}-\d{4}/g)) !== null) {
        var dateOne = new URL(location.href).searchParams.get('date');
        var date = dateOne.replaceAll('-', '/');

        var returnDateOne = new URL(location.href).searchParams.get('returnDate');
        var returnDate = returnDateOne != null ? returnDateOne.replaceAll('-', '/') : returnDateOne;

        if ($('body').hasClass('Ticket-Search') || $('body').hasClass('Search-Show')) {
            setTimeout(() => {
                $('.dates__input.dates__departure').val(date);
                $('.dates__input.dates__back').val(returnDate);
            }, 500);
        }
        if (travelPoints.originCode) {
            $('#origin').val(travelPoints.originCode);
            $('.cities__input.cities__origin').val(travelPoints.originValue);
        } else {
            $('#origin').val(originDestination.originId);
            $('.cities__input.cities__origin').val(
                originDestination.originCity
            );
        }

        if (travelPoints.destinationCode) {
            $('#destination').val(travelPoints.destinationCode);
            $('.cities__input.cities__destination').val(
                travelPoints.destinationValue
            );
        } else {
            $('#destination').val(originDestination.destinationId);
            $('.cities__input.cities__destination').val(
                originDestination.destinationCity
            );
        }
    } else if ($('body').hasClass('Ticket-Search') || $('body').hasClass('Search-Show')) {
        let keepOriginValue = $('input[name="originValue"]').val();
        let keepDestinationValue = $(
                'input[name="destinationValue"]'
            ).val();

        const urlParams = new URLSearchParams(location.search);
        let idOriginInput = urlParams.get('origin');
        let idDestinationInput = urlParams.get('destination');
        let keepNewDate = urlParams.get('date').replace('-', '/');
        let keepNewReturnDate = urlParams.get('returnDate') != null ? urlParams.get('returnDate').replace('-', '/') : null;
        window.onload = () => {
            $('#origin').val(idOriginInput);
            $('#destination').val(idDestinationInput);
            $('#ticketForm .cities__origin').val(keepOriginValue);
            $('#ticketForm .cities__destination').val(keepDestinationValue);

            const regex = /\d{4}\/\d{2}-\d{2}/; // Identifica o seguinte formato: ano/mês/dia === 2023/12/30
            const wrongDepartureFormat = keepNewDate.match(regex);
            const wrongBackFormat = keepNewReturnDate ? keepNewReturnDate.match(regex) : null;
            if (wrongDepartureFormat) {
                const formattedDepartureDate = adjustDateFormat(keepNewDate);
                $('#inputDeparture').val(formattedDepartureDate);

                if (wrongBackFormat) {
                    const formattedBackDate = adjustDateFormat(keepNewReturnDate);
                    $('#inputBack').val(formattedBackDate);
                }
            } else {
                $('#inputDeparture').val(keepNewDate);
                $('#inputBack').val(keepNewReturnDate);
            }
        };
    } else {
        return;
    }
}

function verifyIfIsStudent(isStudent) {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var isStudentResult = isStudent || urlParams.get('isStudent');
    if (isStudentResult === 'true') {
        $('#isStudent').prop('checked', true);
    }
}


module.exports = {
    ready: function () {
        $(document).ready(function () {
            setInterval(() => {
                if(window.location.queryString) {
                    verifyIfIsStudent(window.location.queryString.isStudent);
                }
            }, 500);
        });
        // eslint-disable-next-line no-undef
        jQuery(function () {
            fillTravelPoints();
            verifyIfIsStudent();
            DatePicker.initialize({
                inputIn: document.querySelector('#inputDeparture'),
                inputOut: document.querySelector('#inputBack'),
                autoApply: true,
                optionsIn: optionsIn,
                optionsOut: optionsOut,
                id: 'ticketPicker'
            });

            $('#isStudent').on('change', function () {
                window.location.queryString.isStudent = $(this).is(':checked');
            });

            function disabledPreviousDay() {
                const selectedDate = $('.date-selected').data('date');
                const currentDate = moment().format('YYYY-MM-DD');
                const nextDate = moment().add(1, 'days').format('YYYY-MM-DD');

                if (selectedDate === currentDate) {
                    const itemPrev = $('li.date-selected').prevAll();
                    itemPrev.addClass('disabled');
                }
                if (selectedDate === nextDate) {
                    const itemPrev =
                    $('li.date-selected').siblings('li.slick-current');
                    itemPrev.addClass('disabled');
                }
            }

            function selectAllCityTextOnFocus() {
                $('.cities__input').on('focus', function () {
                    $(this).select();
                });
            }

            disabledPreviousDay();
            selectAllCityTextOnFocus();
            $('.search-mobifacil').on('click', function (e) {
                e.preventDefault();
                var redirecTicketMobiFacil = redirectTicketMobiFacilImport();
                redirecTicketMobiFacil.redirectToMobiFacil();
            });
            $('.close-search-mobifacil').on('click', function (e) {
                e.preventDefault();
                $('#ticketForm .modal').removeClass('show');
            });
        });

        $('.cities__origin').on('change click', function () {
            var originIsClean = $(this).val().length;

            if (originIsClean < 1) {
                sessionStorage.removeItem('lastDestinations');
            }
        });
    },

    loadSearchEvents: function () {
        $('.cities__input.cities__origin').on(
            'keyup',
            delaySearch(function () {
                filterCities('origin', this.value);
            }, 250)
        );

        $('.cities__input.cities__destination').on(
            'keyup',
            delaySearch(function () {
                filterCities('destination', this.value);
            }, 250)
        );

        $(document).on(
            'click',
            '.cities__list-recent .cities__item',
            function () {
                $(this)
                    .closest('.cities__list')
                    .siblings('.cities__input')
                    .val($(this).text());
                $(this)
                    .closest('.cities__list')
                    .next('.cities__input--isHidden')
                    .val($(this).attr('data-id'));
                $(this).closest('.cities__list').hide();
            }
        );

        $('.cities__input').click(function () {
            const handleCities = $(this);

            if (handleCities.val() === '') {
                $('body').addClass('mostPopular');
                $('.cities__list-popular').children().remove();

                setTimeout(() => {
                    filterCities($(this).attr('data-travelpoint'), '');
                }, 300);
            }

            handleCities
                .parent()
                .find('.cities__list-recent')
                .children()
                .remove();
            const inptCities =
                $(this).attr('data-travelpoint') === 'origin'
                    ? 'recentOrigins'
                    : 'recentDestinations';
            const listCookie = readCookie(inptCities) || [];

            const ids = listCookie.map((o) => o.id);
            const sortePlaces = listCookie.filter(
                ({ id }, index) => !ids.includes(id, index + 1)
            );

            const sortePlacesResult = sortePlaces.sort(function (a, b) {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });

            // eslint-disable-next-line array-callback-return
            sortePlacesResult.map((item, index) => {
                handleCities
                    .parent()
                    .find('.cities__list-recent')
                    .append(
                        '<li class="cities__item text--isNormal isPopular" data-id="' +
                            item.id +
                            '"orange-id="result_recent_' + index +
                            '">' +
                            item.name +
                            '</li>'
                    );
            });

            if (!listCookie.length > 0) return;
            // $(this).parent().find('.cities__list').show();
        });

        $('#ticketForm').on('submit', function (event) {
            $('body').trigger('busLoading:init');
            if(window.location.queryString) {
                verifyIfIsStudent(window.location.queryString.isStudent);
            }

            var inputDepartureEl = document.querySelector('#inputDeparture');
            var inputBackEl = document.querySelector('#inputBack');
            var $btnSubmit = $(this).find('.search-tickets-btn');

            var isBackDateValid = DatePicker.validations.isBackDateValid(
                inputDepartureEl,
                inputBackEl
            );

            $btnSubmit.prop('disabled', true);

            setTimeout(() => {
                $btnSubmit.prop('disabled', false);
            }, 1000);

            if (!isBackDateValid) {
                event.preventDefault();
                DatePicker.errors.showMessageBackDateValidade(
                    inputBackEl,
                    'Informe uma data maior ou igual a data de Ida',
                    'is-invalid'
                );

                $('body').trigger('busLoading:stop');
            }else{
                $('body').trigger('clevertap:search');
            }

            saveTravelPoints(
                'origin',
                $('.cities__input.cities__origin').val(),
                $('#origin').val()
            );

            var originCookies = readCookie('recentOrigins');

            if (originCookies == null) {
                originCookies = [];
                originCookies.push({
                    name: $('.cities__input.cities__origin').val(),
                    id: $('#origin').val()
                });
            } else {
                originCookies.push({
                    name: $('.cities__input.cities__origin').val(),
                    id: $('#origin').val()
                });
            }

            var searchCookies = [];
            searchCookies.push({
                origin: $('.cities__input.cities__origin').val(),
                originId: $('#origin').val(),
                destination: $('.cities__input.cities__destination').val(),
                destinationId: $('#destination').val()
            });


            createCookie('recentOrigins', originCookies, 2);
            createCookie('recentSearch', searchCookies, 90);

            saveTravelPoints(
                'destination',
                $('.cities__input.cities__destination').val(),
                $('#destination').val()
            );

            var destinationCookies = readCookie('recentDestinations');

            if (destinationCookies == null) {
                destinationCookies = [];
                destinationCookies.push({
                    name: $('.cities__input.cities__destination').val(),
                    id: $('#destination').val()
                });
            } else {
                destinationCookies.push({
                    name: $('.cities__input.cities__destination').val(),
                    id: $('#destination').val()
                });
            }

            createCookie('recentDestinations', destinationCookies, 2);
            $('body').trigger('amplitude:searchEvent');

            // evento reservamos
            let dateInfo = window.transformDateAndCalculateDays(inputDepartureEl.value)
            const originCity = $('.cities__input.cities__origin').val();
            const destinationCity = $('.cities__input.cities__destination').val();

            let dataSearch = {
                Departure: dateInfo.formattedNewDate,
                "Departure Delta": dateInfo.daysRemaining,
                Destination: destinationCity,
                Origin:originCity,
                "Origin Terminal": originCity,
                Passengers: 1,
                Route: `${originCity} - ${destinationCity}`,
                "Destination Terminal": destinationCity,
            };

            let isStudent = $('#isStudent').prop('checked');

            if (isStudent) {
                dataSearch['Categories'] = ["Estudante"]
            }

            $(window).trigger('reservamos:search', dataSearch);
        });

        $('#btnSwitch').on('click', switchTravelPoints);
    },

    toggleCitiesList: function () {
        var isCitiesListVisible = function () {
            return $('.cities__list').is(':visible');
        };

        var isNotCitiesListAndCitiesInput = function (target) {
            return (
                !$(target).is('.cities__list') &&
                !$(target).is('.cities__input')
            );
        };

        $('body').on('click', function (ev) {
            if (
                isCitiesListVisible() &&
                isNotCitiesListAndCitiesInput(ev.target)
            ) {
                $('.cities__list').hide();
            } else if ($(ev.target).is('.cities__input')) {
                filterOriginsDestinations($(ev.target).data('travelpoint'));
            }
        });
    },
    openEditForm: function () {
        $('.edit__search--button').on('click', function () {
            $('.ticket__editForm').stop().slideToggle();
        });
    },

    applySlick: function () {
        if (!$('.slider-center').length) return;

        $('.slider-center').slick({
            dots: false,
            arrows: true,
            infinite: false,
            autoplay: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '0px',

            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        arrows: false,
                        slidesToShow: 3,
                        centerMode: true,
                        centerPadding: '0px'
                    }
                }
            ]
        });

        var slideInitial = $('.slider-center .date-selected').data(
            'slick-index'
        );

        $('.slider-center').slick('slickGoTo', slideInitial);

        $('.slider-dates').removeClass('loading');

        return this;
    },

    populateSearchInputs: function () {
        if ($('.error-message').length) {
            var data = JSON.parse(localStorage.getItem('searchedData'));

            $('#origin').val(data.originId);
            $('.form-control.cities__input.cities__origin').val(data.origin);

            $('#destination').val(data.destinationId);
            $('.form-control.cities__input.cities__destination').val(data.destination);

            $('#inputDeparture').val(data.departureId);
            $('.form-control.dates__input.dates__departure').val(data.departure);

            $('#inputBack').val(data.arrivalId);
            $('.form-control.dates__input.dates__back').val(data.arrival);

            $('#isStudent').prop('checked', data.isStudent);
            $('#isPCD').prop('checked', data.isPCD);
        }
    },

    recentSearchPopulateFields: function () {
        var originInput = $('.form-control.cities__input.cities__origin');
        var destinationInput = $('.form-control.cities__input.cities__destination');
        var recentSearch = readCookie('recentSearch') ? readCookie('recentSearch') : false;
        let urlParams = new URLSearchParams(window.location.search);
        let origin = urlParams.get('origin');
        let destination = urlParams.get('destination');

        if (origin && destination) {
            recentSearch = [{
                origin: $('.originValue').text().trim(),
                originId: origin,
                destination: $('.destinationValue').text().trim(),
                destinationId: destination
            }];
            createCookie('recentSearch', recentSearch, 90);
        }

        $('body').ready(function () {
            if (originInput && destinationInput && recentSearch && recentSearch.length >= 0) {
                $(originInput).val(recentSearch[0].origin);
                $('#origin').val(recentSearch[0].originId);

                $(destinationInput).val(recentSearch[0].destination);
                $('#destination').val(recentSearch[0].destinationId);
            }
        });

        $('.cities__input.cities__origin, .cities__input.cities__destination').on('focus', function () {
            if ($(this).hasClass('cities__origin')) {
                $(originInput).val('');
                $('#origin').val('');
                $(destinationInput).val('');
                $('#destination').val('');
            } else if ($(this).hasClass('cities__destination')) {
                $(destinationInput).val('');
                $('#destination').val('');
            }
        });
    },
    trackEvents: function () {
        window.addEventListener("Tracker Ready", function () {
            if ($('.Search-Show, .Ticket-Search').length > 0) {
                let dateInfo = window.transformDateAndCalculateDays($('#inputDeparture').val())
                const originCity =$('.originValue').text().trim();
                const destinationCity =$('.destinationValue').text().trim();

                let dataSearch = {
                    "Bus count": $('.ticket__list').children().length,
                    Departure: dateInfo.formattedNewDate,
                    "Departure Delta": dateInfo.daysRemaining,
                    Destination: destinationCity,
                    Origin: originCity,
                    "Origin Terminal": originCity,
                    Route: `${originCity} - ${destinationCity}`,
                    "Destination Terminal": destinationCity,
                };

                $(window).trigger('reservamos:viewResults', dataSearch);

            }
        });
    },
    ia:function () {
        $('.cities__input, #inputDeparture, #inputBack').on('click', function (event) {
            let clickedField;

            if ($(event.target).hasClass('cities__input')) {
                clickedField = $(event.target).data('travelpoint');
            } else if (event.target.id === 'inputDeparture') {
                clickedField = 'departure';
            } else if (event.target.id === 'inputBack') {
                clickedField = 'return';
            }

            $(window).trigger('reservamos:interestInSearch', {
                'Field': clickedField
            });
        });
    }
};
