'use strict';

var processInclude = require('./util');

$(document).ready(function () {
    processInclude(require('./ticket/ticket'));
    processInclude(require('./cleverTap/cleverTap'));
    processInclude(require('./utils/mobileCheck'));
    processInclude(require('./utils/webviewCheck'));
});
